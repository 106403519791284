import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Integrations",
  "sort": 1,
  "title": ".NET (C#)",
  "subtitle": "Integrate Criipto Signatures with C#/dotnet to sign PDFs using MitID, Swedish BankID, Norwegian BankId or other eIDs."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide shows you how to use implement Criipto Signatures in .NET to sign
PAdeS-LTA documents using MitID, BankID or any other eID supported by Criipto Verify.`}</p>
    <h2>{`Getting started`}</h2>
    <h3>{`Requirements`}</h3>
    <p>{`This library supports .NET 6 and .NET 7.`}</p>
    <h3>{`Installation`}</h3>
    <p>{`The SDK is available on `}<a parentName="p" {...{
        "href": "https://www.nuget.org/packages/Criipto.Signatures"
      }}>{`Nuget`}</a>{` and can be installed using the Package Manager Console or the dotnet CLI:`}</p>
    <pre><code parentName="pre" {...{}}>{`Install-Package Criipto.Signatures
dotnet add package Criipto.Signatures
`}</code></pre>
    <h3>{`Configure the SDK`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`var client = new CriiptoSignaturesClient("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}");
`}</code></pre>
    <h2>{`Creating your first signature order`}</h2>
    <p>{`Create a signature order by `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using Criipto.Signatures;
using Criipto.Signatures.Models;

using (var client = new CriiptoSignaturesClient("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}")) {
    var pdfFile = File.ReadAllBytes("...some pdf file");

    // Setup document input
    var documents = new List<DocumentInput>{
        new DocumentInput {
            pdf = new PadesDocumentInput {
                title = "Dotnet Sample",
                blob = pdfFile,
                storageMode = DocumentStorageMode.Temporary
            }
        }
    };

    // Setup signature order input
    var createSignatureOrderInput = new CreateSignatureOrderInput
    {
        title = "Dotnet Sample",
        documents = documents
    };

    // Create signature order
    var signatureOrder = await client.CreateSignatureOrder(createSignatureOrderInput);

    // ... store the signatureOrder.id
}
`}</code></pre>
    <h3>{`Adding signatories`}</h3>
    <p>{`Signatories are the individuals you wish to sign your documents.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using Criipto.Signatures;
using Criipto.Signatures.Models;

using (var client = new CriiptoSignaturesClient("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}")) {
    var signatureOrderId = "..." // The id from when you previosuly created the signature order

    // Add signatory to signature order
    var signatory = await client.AddSignatory(signatureOrderId);

    Console.WriteLine(signatory.href); // The signatory.href is the signing link that you can show or send to your users
}
`}</code></pre>
    <h3>{`Closing the signature order`}</h3>
    <p>{`Once everyone has signed you must close the signature order to finalize the process and extract the signed documents.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using Criipto.Signatures;
using Criipto.Signatures.Models;

using (var client = new CriiptoSignaturesClient("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}")) {
    var signatureOrderId = "..." // The id from when you previosuly created the signature order

    // Close signature order
    var closedSignatureOrder = await client.CloseSignatureOrder(signatureOrderId);

    foreach (var document in closedSignatureOrder.documents)
    {
        // document.blob is a byte array containing the signed PDF
    }
}
`}</code></pre>
    <h2>{`More examples`}</h2>
    <p>{`More `}<a parentName="p" {...{
        "href": "/signatures/graphql/examples/"
      }}>{`.NET/C# examples`}</a>{` are provided for all the options supported by the Criipto Signatures API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      